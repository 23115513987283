import React, {useCallback, useState} from "react";

import styles from "./styles.module.scss";
import {Loader, UpgradeNowTooltip} from "components";
import colors from "styles/themes.module.scss";
import css from "mapx-pages/Project/ProjectTitle/projectTitle.module.scss";
import {LockIcon} from "assets/icons";
import {
	prepareDateForFileNameSuffix,
	prepareSpreadSheetFileAndDownload,
} from "helpers/documentHelpers";
import mapxProjectApi from "api/projectApi";
import {Mixpanel} from "helpers/mixpanel";
import {isStarterTypeUserSelector, userEmailSelector} from "store/mapx/user/userSelectors";
import {useAppSelector} from "hooks";
import {projectSelector} from "store/mapx/project-list/projectListSelectors";
import {STProject} from "api/projectApi/types";
import SpreadSheetIcon from "mapx-pages/Project/Share/Icons/spreadsheetIcon";
import {successResponse} from "helpers/map";
import ProjectCollaboration from "mapx-pages/Project/Share/ProjectCollaboration";

const ShareProject = () => {
	const userEmail = useAppSelector(userEmailSelector);

	const project: STProject = useAppSelector(projectSelector);

	const isStarterTypeUser = useAppSelector(isStarterTypeUserSelector);

	const [downloadProcessing, setDownloadProcessing] = useState(false);

	const LOCK_ICON_COMPONENT = <LockIcon className={css.downloadIcon} />;

	const DOWNLOAD_ICON_COMPONENT = <SpreadSheetIcon />;

	const [activeIcon, setActiveIcon] = useState(DOWNLOAD_ICON_COMPONENT);

	const LoaderComponent = (
		<Loader
			height={24}
			width={24}
			type="ThreeDots"
			customStyle={{display: "flex", alignItems: "center", margin: "0 5px"}}
			color={colors.loaderDotColor}
		/>
	);

	const handleDownloadExcel = useCallback(async () => {
		if (isStarterTypeUser) return;

		setDownloadProcessing(true);

		const projectName = project?.name ?? "Unnamed Project";
		const formattedDate = prepareDateForFileNameSuffix();
		const fileName = `${projectName}-${formattedDate}`;

		const response = await mapxProjectApi.exportProject(project?.id);

		if (successResponse(response, 200)) {
			prepareSpreadSheetFileAndDownload(response.data, fileName);
		}

		setDownloadProcessing(false);

		Mixpanel.track(`Project Downloaded`, {
			name: `${project.name}`,
			pageTitle: `${project.name}`,
			url: window.location.pathname,
			distinct_id: userEmail,
		});
	}, [isStarterTypeUser, project?.name, project?.id, userEmail]);

	return (
		<div className={styles.container}>
			<div className={styles.section}>
				<div className={styles.sectionTitle}>Download Your Project</div>

				<div className={styles.sectionContent}>
					{downloadProcessing ? (
						LoaderComponent
					) : (
						<span
							className={styles.downloadButton}
							onClick={handleDownloadExcel}
							onMouseEnter={() => {
								if (isStarterTypeUser) {
									setActiveIcon(LOCK_ICON_COMPONENT);
								}
							}}
							onMouseLeave={() => {
								if (isStarterTypeUser) {
									setActiveIcon(DOWNLOAD_ICON_COMPONENT);
								}
							}}
							data-tooltip-id={"downloadProjectReport"}
						>
							{activeIcon} Download Project into Spreadsheet
							{isStarterTypeUser && (
								<UpgradeNowTooltip id={"downloadProjectReport"} />
							)}
						</span>
					)}
				</div>
			</div>

			<div className={styles.section}>
				<div className={styles.sectionTitle}>Project Collaboration</div>

				<div className={styles.sectionContent}>
					<ProjectCollaboration />
				</div>
			</div>
		</div>
	);
};

export default ShareProject;
