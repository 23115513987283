import React, {useRef, useState} from "react";
import styles from "./styles.module.scss";
import {SearchAutogrowInput} from "mapx-components/index";
import {BiSearch} from "react-icons/bi";
import {LightBulbIcon} from "assets/icons";
import {useOutsideClick} from "hooks";
import {TSearchRequestProps} from "mapx-components/TextSearchRequest/types";

const suggestions: string[] = [
	"Ideas for Google’s next CTO",
	"Market Access Director for large US based pharma and life sciences business",
	"Top AI Talent in San Francisco",
	"M&A Directors based in the US working for businesses like JP Morgan",
];

const TextSearchRequest = ({
	searchText,
	setSearchText,
	loading,
	handleButtonClick,
	fromProjectModal = false,
}: TSearchRequestProps) => {
	const [showSuggestions, setShowSuggestions] = useState<boolean>(false);

	const searchRef = useRef(null);

	const [height, setHeight] = useState(50);

	const handleInputChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
		const value = e.target.value?.trim();
		setSearchText(value);
		// Show suggestions only when input is not empty
		setShowSuggestions(value !== "");
	};

	const handleKeyboardEvent = (e: React.KeyboardEvent) => {
		if (e.key === "Enter" && !e.shiftKey) {
			setShowSuggestions(false);

			if (handleButtonClick) {
				handleButtonClick();
			}
		} else if (e.key === "Enter" && e.shiftKey) {
			setSearchText((prevText) => prevText + "\n");
		}
	};

	useOutsideClick(searchRef, () => setShowSuggestions(false));

	return (
		<div
			className={styles.searchBar}
			ref={searchRef}
			style={{minHeight: fromProjectModal || showSuggestions ? 200 : "auto"}}
		>
			<SearchAutogrowInput
				id={"searchInput"}
				customWrapperClass={styles.inputContainer}
				placeholder="Describe a role at a company or industry..."
				value={searchText}
				onChange={handleInputChange}
				onKeyDown={handleKeyboardEvent}
				onFocus={() => setShowSuggestions(true)}
				preIcon={
					fromProjectModal ? undefined : (
						<BiSearch size={24} className={styles.searchIcon} color="#808080" />
					)
				}
				maxHeight={150}
				disabled={loading}
				setInputHeight={setHeight}
			/>

			{(fromProjectModal ? true : showSuggestions) && (
				<div className={styles.suggestionsContainer} style={{top: height}}>
					<div className={styles.exampleTitle}>
						<LightBulbIcon /> Examples
					</div>
					<ul className={styles.suggestionsList}>
						{suggestions.map((suggestion, index) => (
							<li key={index} className={styles.suggestion}>
								{suggestion}
							</li>
						))}
					</ul>
				</div>
			)}
		</div>
	);
};

export default TextSearchRequest;
