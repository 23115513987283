import {DriveFolderUploadIcon, MarketMapsIcon} from "assets/icons";
import {SelectWithTooltip} from "components";
import {noop} from "lodash";
import {TextTabPane, TextTabs} from "mapx-components";
import ProjectTitle from "mapx-pages/Project/ProjectTitle";
import SavedCompanies from "mapx-pages/Project/SavedCompanies";
import SavedPeople from "mapx-pages/Project/SavedPeople";
import {useCallback, useEffect, useMemo} from "react";
import {useSelector} from "react-redux";

import {useHistory, useParams} from "react-router-dom";

import {addFromMarketMapModal, createMarketMapModal} from "store/mapx/market-map/marketMapActions";
import {setProject, setProjectTabIndex} from "store/mapx/project-list/projectListActions";
import {getProject} from "store/mapx/project-list/projectListAsyncActions";
import {
	getProjectInProgressSelector,
	projectSelector,
	projectsSelector,
	projectTabIndexSelector,
} from "store/mapx/project-list/projectListSelectors";
import {getTargetListCompanies} from "store/mapx/target-list/targetListAsyncActions";
import {getTargetListCandidates} from "store/mapx/target-list/targetListCandidatesAsyncActions";
import {
	targetCandidatesPaginationSelector,
	targetCompaniesPaginationSelector,
	targetListCompaniesSelector,
	targetListsInProgressSelector,
} from "store/mapx/target-list/targetListSelectors";
import {
	hasAccessToMarketMapsSelector,
	userLicenseTypeNameSelector,
} from "store/mapx/user/userSelectors";
import IndustriesReport from "./Overview/IndustriesReport";
import {Insights} from "./Insights";
import styles from "./project.module.scss";
import ProjectStats from "./Overview/ProjectStats";
import {useAppDispatch} from "hooks";
import {clearAPWorkflowState} from "store/mapx/additional-profiles/additionalProfilesActions";
import {updateProjectSuccessChecklistItem} from "store/mapx/project/projectSuccessChecklistAsyncActions";
import ProjectTabs from "mapx-pages/Project/ProjectTabs";
import ShareProject from "mapx-pages/Project/Share";
import {STProject} from "api/projectApi/types";

const ProjectDetails = () => {
	const dispatch = useAppDispatch();

	const targetListsInProgress = useSelector(targetListsInProgressSelector);

	const project: STProject = useSelector(projectSelector);

	const projects = useSelector(projectsSelector);

	const savedPeoplePagination = useSelector(targetCandidatesPaginationSelector);

	const targetCompaniesPagination = useSelector(targetCompaniesPaginationSelector);

	const targetListCompanies = useSelector(targetListCompaniesSelector);

	const getProjectInProgress = useSelector(getProjectInProgressSelector);

	const hasAccessToMarketMaps = useSelector(hasAccessToMarketMapsSelector);

	const loading = targetListsInProgress || getProjectInProgress;

	const projectTabIndex = useSelector(projectTabIndexSelector);

	const userLicenseTypeName = useSelector(userLicenseTypeNameSelector);

	const history = useHistory();

	const params = useParams();

	const projectID = useMemo(
		() => ("id" in params ? parseInt(params.id as string) : null),
		[params],
	);

	const handleTabChange = (idx: number) => {
		dispatch(setProjectTabIndex(idx));

		if (idx === ProjectTabs.insightsTabIndex) {
			dispatch(
				updateProjectSuccessChecklistItem({
					attribute: "has_viewed_insights",
					value: true,
				}),
			);
		}
	};

	const handleAddFromMarketMap = useCallback(() => {
		if (project?.target_list_id) {
			dispatch(addFromMarketMapModal(true, {targetListID: project.target_list_id}));
		}
	}, [dispatch, project?.target_list_id]);

	const handleModalDisplay = useCallback(
		(open: boolean) => {
			dispatch(
				createMarketMapModal(
					open,
					open
						? {
								companies: targetListCompanies,
								description: "",
								is_global: false,
								name: "",
						  }
						: {},
				),
			);
		},
		[dispatch, targetListCompanies],
	);

	// projects.length here is required in order to provide stable /project/:id
	// url. Otherwise, the project in the state will be the previously selected
	// project and will show up on url temporarily during the GET /project/:id
	// fetch.
	// [DISABLED FOR NOW]
	useEffect(() => {
		// if (project?.id && projects?.length > 0) {
		// 	window.history.replaceState(null, "", `/project/${project.id}`);
		// }

		async function getProjectDetails(projectID: number) {
			dispatch(clearAPWorkflowState());
			dispatch(setProject(projectID));

			await dispatch(getProject(projectID));
			dispatch(getTargetListCompanies());
			dispatch(getTargetListCandidates());

			history.push(`/project/${projectID}`);
		}

		if ((project?.id === undefined && projectID) || project?.id !== projectID) {
			if (projectID) {
				getProjectDetails(projectID).then((r) => r);
			} else {
				history.push("/projects");
			}
		}
	}, [dispatch, history, project?.id, projectID, projects?.length]);

	const overviewTabContent = useMemo(
		() => (
			<div className={styles.listedItems}>
				<ProjectStats />
				<IndustriesReport />
			</div>
		),
		[],
	);

	const tabs = useMemo(() => {
		const overviewTab = (
			<TextTabPane
				title="Overview"
				// icon={<OverviewIcon/>}
				key="overview"
			>
				<span className={styles.tabContainer}>{overviewTabContent}</span>
			</TextTabPane>
		);

		const candidateTab = (
			<TextTabPane
				title="Saved People"
				// icon={<IconUsers />}
				count={savedPeoplePagination?.count ?? 0}
				key="people"
			>
				<span className={styles.tabContainer}>
					<SavedPeople />
				</span>
			</TextTabPane>
		);

		const companyTab = (
			<TextTabPane
				title="Companies"
				// icon={<CompanyAsBuildingIcon />}
				count={targetCompaniesPagination?.count ?? 0}
				key="company"
			>
				{hasAccessToMarketMaps && (
					<div className={styles.selectContainer}>
						<SelectWithTooltip
							icon={<MarketMapsIcon width={22} height={22} />}
							className={styles.select}
							placeholder="Import"
							options={[
								{
									label: "Add from Target List",
									action:
										userLicenseTypeName !== ("Starter" || "Plus")
											? handleAddFromMarketMap
											: noop,
									icon: <MarketMapsIcon width={22} height={22} />,
								},
								{
									label: "Save as Target List",
									action:
										userLicenseTypeName !== ("Starter" || "Plus")
											? () => handleModalDisplay(true)
											: noop,
									icon: <DriveFolderUploadIcon width={24} height={24} />,
								},
							]}
						/>
					</div>
				)}

				<span className={styles.tabContainer}>
					<SavedCompanies />
				</span>
			</TextTabPane>
		);

		const insightTab = (
			<TextTabPane
				title="Insights"
				// icon={<InsightsIcon />}
				key="insights"
			>
				<Insights />
			</TextTabPane>
		);

		const shareTab = (
			<TextTabPane
				title="Share"
				// icon={<InsightsIcon />}
				key="share"
			>
				<ShareProject />
			</TextTabPane>
		);

		return [overviewTab, candidateTab, companyTab, insightTab, shareTab];
	}, [
		handleAddFromMarketMap,
		handleModalDisplay,
		hasAccessToMarketMaps,
		overviewTabContent,
		savedPeoplePagination,
		targetCompaniesPagination,
		userLicenseTypeName,
	]);

	return project && project?.id === projectID ? (
		<div className={styles.container} key={`project-${project?.id}`}>
			<ProjectTitle loading={loading} />

			<div className={styles.targetTabArea}>
				<div className={styles.tabs}>
					<TextTabs activeTab={projectTabIndex} onTabChange={handleTabChange}>
						{tabs.map((tab) => tab)}
					</TextTabs>
				</div>
			</div>
		</div>
	) : (
		<div />
	);
};

export default ProjectDetails;
