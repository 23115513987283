import {PencilIconButton} from "assets/icons";
import {useAppDispatch, useOutsideClick} from "hooks";
import useTrackProjectDetails from "mapx-pages/Project/ProjectTitle/TrackProjectDetails";
import React, {useEffect, useMemo, useRef} from "react";
import {useSelector} from "react-redux";
import {
	editProjectModalSelector,
	projectSelector,
} from "store/mapx/project-list/projectListSelectors";
import {userSelector} from "store/mapx/user/userSelectors";

import styles from "./projectTitle.module.scss";
import {TProjectTitleProps} from "mapx-pages/Project/ProjectTitle/types";
import {STProject} from "api/projectApi/types";
import {editProjectModal, setProjectForm} from "store/mapx/project-list/projectListActions";
import ProjectEditModal from "mapx-components/Modals/ProjectEditModal";
import useLocationSearchService from "services/useLocationSearchService";

const ProjectTitle = ({loading}: TProjectTitleProps) => {
	const project: STProject = useSelector(projectSelector);

	const displayEditModal = useSelector(editProjectModalSelector);

	const projectModalRef = useRef(null);

	const {locations} = useLocationSearchService();

	const user = useSelector(userSelector);

	const dispatch = useAppDispatch();

	/**
	 * HANDLING PROJECT EDIT
	 * @param projectId
	 * @returns {Promise<void>}
	 */
	useOutsideClick(projectModalRef, () => {
		dispatch(editProjectModal(false));
	});

	const formData = useMemo(() => {
		return {
			id: project.id,
			name: project.name,
			location: project.location ? locations.find((l) => l.id === project.location) : null,
			users:
				project.users && project.users?.length > 0
					? [...project.users].filter((u) => u.id !== user.user_id)
					: [],
		};
	}, [locations, project, user]);

	const handleEditProjectClick = () => {
		dispatch(editProjectModal(true));

		dispatch(setProjectForm(formData));
	};

	useEffect(() => {
		if (displayEditModal) {
			dispatch(setProjectForm(formData));

			// if (editProjectModalProps && editProjectModalProps?.only_share) {
			//     setOnlyProjectShareOption(true);
			// }
		}
	}, [dispatch, displayEditModal, formData]);

	/**
	 * MIX PANEL & GOOGLE ANALYTICS TRACK LOG
	 */
	useTrackProjectDetails();

	return (
		<div>
			<div className={styles.projectTitle}>
				<div className={styles.titleContainer}>
					<h3 className={styles.titleContainer__text}>
						{project && project.name !== null ? project.name : "Unnamed Project"}
					</h3>
				</div>

				{!loading && (
					<div className={styles.buttonContainer}>
						<span className={styles.editProject} onClick={handleEditProjectClick}>
							<PencilIconButton />
							Edit Project
						</span>
					</div>
				)}
			</div>
			{displayEditModal && (
				<div data-testid="project-edit-modal">
					<ProjectEditModal />
				</div>
			)}
		</div>
	);
};

export default ProjectTitle;
