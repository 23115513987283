import React from "react";

const TelephoneIcon = () => {
	return (
		<svg
			width="20"
			height="20"
			viewBox="0 0 20 20"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<mask
				id="mask0_946_13691"
				style={{maskType: "alpha"}}
				maskUnits="userSpaceOnUse"
				x="0"
				y="0"
				width="20"
				height="20"
			>
				<rect width="20" height="20" fill="#D9D9D9" />
			</mask>
			<g mask="url(#mask0_946_13691)">
				<path
					d="M15.8958 16.9792C14.2153 16.8542 12.6215 16.441 11.1146 15.7396C9.60764 15.0382 8.25695 14.0903 7.06251 12.8958C5.86806 11.7014 4.92709 10.3542 4.23959 8.85417C3.55209 7.35417 3.13889 5.76389 3.00001 4.08333C2.97223 3.79167 3.05755 3.53819 3.25596 3.32292C3.45437 3.10764 3.70238 3 4.00001 3H6.83334C7.06945 3 7.27431 3.07292 7.44792 3.21875C7.62153 3.36458 7.73612 3.54861 7.79167 3.77083L8.29167 5.97917C8.31945 6.15972 8.30903 6.33333 8.26042 6.5C8.21181 6.66667 8.13195 6.80556 8.02084 6.91667L6.00001 8.95833C6.27778 9.48611 6.59723 9.99306 6.95834 10.4792C7.31945 10.9653 7.72201 11.4235 8.16603 11.8538C8.58312 12.2707 9.02778 12.6562 9.50001 13.0104C9.97223 13.3646 10.4722 13.6806 11 13.9583L13.0625 11.9583C13.1736 11.8472 13.3125 11.7674 13.4792 11.7188C13.6458 11.6701 13.8195 11.6597 14 11.6875L16.2292 12.1667C16.4653 12.2361 16.6528 12.3576 16.7917 12.5312C16.9306 12.7049 17 12.9097 17 13.1458V15.9792C17 16.2768 16.8889 16.5248 16.6667 16.7232C16.4444 16.9216 16.1875 17.0069 15.8958 16.9792ZM5.31251 7.5L6.77084 6.04167L6.41334 4.5H4.54167C4.61112 5.02778 4.70834 5.53819 4.83334 6.03125C4.95834 6.52431 5.11806 7.01389 5.31251 7.5ZM12.4792 14.6667C12.9667 14.8645 13.4635 15.0216 13.9698 15.138C14.476 15.2543 14.9861 15.3472 15.5 15.4167V13.5417L13.9375 13.2083L12.4792 14.6667Z"
					fill="#4E5555"
				/>
			</g>
		</svg>
	);
};

export default TelephoneIcon;
