import React from "react";

const ForwardToMailIcon = () => {
	return (
		<svg
			width="20"
			height="20"
			viewBox="0 0 20 20"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<mask
				id="mask0_946_13711"
				style={{maskType: "alpha"}}
				maskUnits="userSpaceOnUse"
				x="0"
				y="0"
				width="20"
				height="20"
			>
				<rect width="20" height="20" fill="#D9D9D9" />
			</mask>
			<g mask="url(#mask0_946_13711)">
				<path
					d="M10 11L3.5 7.27083V14.5H10.5V16H3.5C3.0875 16 2.73438 15.8531 2.44063 15.5592C2.14688 15.2653 2 14.9119 2 14.4992V5.49417C2 5.08139 2.14688 4.72917 2.44063 4.4375C2.73438 4.14583 3.0875 4 3.5 4H16.5C16.9125 4 17.2656 4.14688 17.5594 4.44063C17.8531 4.73438 18 5.0875 18 5.5V10H16.5V7.27083L10 11ZM10 9.22917L16.5 5.5H3.5L10 9.22917ZM3.5 7.27083V15.2083V5.5V7.27083Z"
					fill="#4E5555"
				/>
				<path
					d="M12.5714 19H19.4286C19.5905 19 19.7262 18.9452 19.8357 18.8357C19.9452 18.7262 20 18.5905 20 18.4286V13.5714C20 13.4095 19.9452 13.2738 19.8357 13.1643C19.7262 13.0548 19.5905 13 19.4286 13H18V11.5714C18 11.4095 17.9452 11.2738 17.8357 11.1643C17.7262 11.0548 17.5905 11 17.4286 11H14.5714C14.4095 11 14.2738 11.0548 14.1643 11.1643C14.0548 11.2738 14 11.4095 14 11.5714V13H12.5714C12.4095 13 12.2738 13.0548 12.1643 13.1643C12.0548 13.2738 12 13.4095 12 13.5714V18.4286C12 18.5905 12.0548 18.7262 12.1643 18.8357C12.2738 18.9452 12.4095 19 12.5714 19ZM14.8571 13V12H17.1429V13H14.8571Z"
					fill="#4E5555"
				/>
			</g>
		</svg>
	);
};

export default ForwardToMailIcon;
