import React, {type ChangeEvent, useCallback, useMemo, useState} from "react";
import styles from "./styles.module.scss";
import SelectedProjectTypeHeader from "mapx-components/Modals/ProjectCreateWithWorkflowsModal/SelectedProjectTypeHeader";
import {TextInput} from "components";
import FooterButtons from "mapx-components/Modals/ProjectCreateWithWorkflowsModal/FooterButtons";
import {
	createProjectModal,
	setProjectWorkFlowStep,
} from "store/mapx/project-list/projectListActions";
import {useAppDispatch} from "hooks";
import {saveProjectForAI} from "store/mapx/project-list/projectWorkflow";
import {useHistory} from "react-router-dom";
import placeHolderImage from "assets/images/avatar-icon.png";
import SelectDropdown from "components/Inputs/SelectDropdown";
import {STCompany} from "api/companyApi/types";
import {TStep} from "mapx-components/Modals/ProjectCreateWithWorkflowsModal/StartWithCompanies/types";
import StartWithCompaniesIcon from "mapx-components/Modals/ProjectCreateWithWorkflowsModal/Icons/StartWithCompaniesIcon";
import useCompanySearchService from "services/useCompanySearchService";
import useLocationSearchService from "services/useLocationSearchService";
import {STCountry} from "api/filterOptionsApi/CountryApi/types";
import {setSelectedCompaniesDone} from "store/mapx/company/companyActions";
import {generateCompanyAttributeAsFilterSet} from "helpers/Filters/extractCompaniesAttributeAsFilter";
import {setFilterForCompaniesByCompaniesList} from "store/mapx/filter/filterActions";
import {addCompanyToTargetList} from "store/mapx/target-list/targetListAsyncActions";
import {updateProjectSuccessChecklistItem} from "store/mapx/project/projectSuccessChecklistAsyncActions";

const StartWithCompanies = () => {
	const dispatch = useAppDispatch();

	const {loadingCompanies, fetchCompanies} = useCompanySearchService();

	const {loadingLocations, flattenLocations} = useLocationSearchService();

	const [step, setStep] = useState<TStep>("project name");

	const [name, setName] = useState("");

	const [loading, setLoading] = useState<boolean>(false);

	const [companies, setCompanies] = useState<STCompany[]>([]);

	const [location, setLocation] = useState<Nullable<STCountry>>(null);

	const history = useHistory();

	const handleNameChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
		setName(e.target.value);
	}, []);

	const handleGoBack = useCallback(() => {
		if (step === "select companies") {
			setStep("project name");
		} else {
			dispatch(setProjectWorkFlowStep(null));
		}
	}, [dispatch, step]);

	const handleCreateNewProjectButtonClick = useCallback(async () => {
		if (companies?.length < 1) return;

		setLoading(true);

		const newProject = await dispatch(saveProjectForAI({name}));

		if (newProject) {
			dispatch(setSelectedCompaniesDone([...companies]));

			companies.forEach((company) => {
				dispatch(addCompanyToTargetList(company.id));
			});

			const filters = generateCompanyAttributeAsFilterSet(companies, true);

			const filterObject = {...filters};

			if (location) {
				delete filterObject.companies;
				filters.countries = [location.id as number];
			}

			dispatch(setFilterForCompaniesByCompaniesList(filters));

			dispatch(createProjectModal(false));

			await dispatch(
				updateProjectSuccessChecklistItem({
					attribute: "has_saved_companies",
					value: true,
				}),
			);

			history.push("/companies");
		}

		setLoading(false);
	}, [companies, dispatch, name, history, location]);

	const isActionButtonDisabled = useMemo(() => {
		if (step === "project name") {
			return !(name !== "" && name?.length > 3);
		} else if (step === "select companies") {
			return !(companies?.length > 0);
		} else {
			return false;
		}
	}, [step, name, companies?.length]);

	const handleActionButtonClick = useCallback(() => {
		if (step === "project name") {
			setStep("select companies");
		} else {
			handleCreateNewProjectButtonClick();
		}
	}, [step, handleCreateNewProjectButtonClick]);

	const headerInfo = useMemo(() => {
		let info: {description: string; title: string};

		if (step === "project name") {
			info = {
				title: "Name Your Project",
				description: "Start by giving your project a name",
			};
		} else {
			info = {
				title: "Pick Some Example Companies",
				description: "Provide some companies to search in and MapX will suggest more",
			};
		}

		return info;
	}, [step]);

	const handleEnterPress = (event: React.KeyboardEvent) => {
		if (event.code === "Enter" || event.code === "NumpadEnter") {
			event.preventDefault();

			if (!isActionButtonDisabled) {
				handleActionButtonClick();
			}
		}
	};

	return (
		<div>
			<div className={styles.container} onKeyDown={handleEnterPress}>
				<SelectedProjectTypeHeader
					title={headerInfo.title}
					description={headerInfo.description}
					icon={<StartWithCompaniesIcon />}
				/>

				{step === "project name" && (
					<div className={styles.content}>
						<TextInput
							label="Project Name*"
							onChange={handleNameChange}
							type="text"
							value={name}
							placeholder={"Enter a project name"}
						/>
					</div>
				)}

				{step === "select companies" && (
					<div className={styles.content}>
						<div className={styles.inputContainer}>
							<label htmlFor="example companies">
								Example Companies *<span className={styles.note}> (Max: 5)</span>
							</label>

							<SelectDropdown
								aria-label={"companies"}
								inputId={"companies"}
								name={"companies"}
								isMulti={true}
								cacheOptions
								isAsync={true}
								borderColor="#dadada"
								value={companies}
								onChange={setCompanies}
								loadOptions={fetchCompanies}
								showClearIcon={!loadingCompanies}
								isSearchable={companies?.length < 5}
								isOptionDisabled={() => companies?.length >= 5}
								openMenuOnClick={companies?.length < 5}
								isClearable={true}
								hideIndicator={true}
								isLoading={loadingCompanies}
								placeholder={"Search for a company"}
								noOptionsMessage={"Search by company name"}
								noResultsText={
									"No company found with your query, try to refine your query"
								}
								formatOptionLabel={(candidate: STCompany) => (
									<div className={styles.dropdownItem}>
										<img
											src={candidate.logo_url || placeHolderImage}
											alt={candidate.name}
										/>
										<span>{candidate.name}</span>
									</div>
								)}
							/>
						</div>

						<div className={styles.inputContainer}>
							<label htmlFor={"Location"}>Office Location</label>

							<SelectDropdown
								borderColor="#dadada"
								value={location}
								onChange={setLocation}
								options={flattenLocations}
								showClearIcon={!loadingLocations}
								isSearchable={true}
								isClearable={true}
								isLoading={loadingLocations}
								placeholder={
									"Do they need to have an office in a certain location?"
								}
								noOptionsMessage={"select office location"}
								noResultsText={
									"No location found with your query, try to refine your query"
								}
								menuPortalTarget={document.body}
								menuPosition={"fixed"}
							/>
						</div>
					</div>
				)}
			</div>

			<FooterButtons
				actionButtonLabel={step === "select companies" ? "Create New Project" : "Continue"}
				handleGoButtonClick={handleGoBack}
				handleActionButtonClick={handleActionButtonClick}
				isActionButtonDisabled={isActionButtonDisabled}
				isLoading={loading}
			/>
		</div>
	);
};

export default StartWithCompanies;
