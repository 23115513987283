import {TRootState} from "types";

export const projectStatsSelector = (state: TRootState) => state.mapxProject.projectStats;

export const projectStatsLoadingSelector = (state: TRootState) =>
	state.mapxProject.projectStatsLoading;

export const industryReportLoadingSelector = (state: TRootState) =>
	state.mapxProject.industryReportLoading;

export const industryReportSelector = (state: TRootState) => state.mapxProject.industryReport;

export const projectFrequentlyHiredFromDataSelector = (state: TRootState) =>
	state.mapxProject.projectFrequentlyHiredFromData;

export const industryBackgroundReportSelector = (state: TRootState) =>
	state.mapxProject.industryBackgroundReport;

export const industryBackgroundReportProgressSelector = (state: TRootState) =>
	state.mapxProject.industryBackgroundReportProgress;

export const jobFunctionDistributionInProgressSelector = (state: TRootState) =>
	state.mapxProject.industryBackgroundReportProgress;
export const jobFunctionDistributionSelector = (state: TRootState) =>
	state.mapxProject.insights.jobFunctionDistribution;

export const onboardingForStarterUserSelector = (state: TRootState) =>
	state.mapxProject.openOnboardingComponent;

export const onboardingStepSelector = (state: TRootState) => state.mapxProject.onboardingStep;
export const displayFullProjectChecklistSelector = (state: TRootState) =>
	state.mapxProject?.displayFullProjectChecklist;

export const projectSuccessChecklistOptionsSelector = (state: TRootState) =>
	state.mapxProject.projectChecklistOptions || [];
