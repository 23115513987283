import React from "react";
import styles from "../projectCandidateCard.module.scss";
import TelephoneIcon from "mapx-components/Cards/ProjectCandidateCard/CandidateContactDetails/Icons/TelephoneIcon";
import MailIcon from "mapx-components/Cards/ProjectCandidateCard/CandidateContactDetails/Icons/MailIcon";
import ForwardToMailIcon from "mapx-components/Cards/ProjectCandidateCard/CandidateContactDetails/Icons/ForwardToMailIcon";

const EmptyContactDetails = () => {
	return (
		<>
			<div className={styles.noInfoGroup} data-testid="empty-contact-details">
				<span>
					<TelephoneIcon />
				</span>
				<span>XXX-XXXX-XXX</span>
			</div>

			<div className={styles.noInfoGroup} data-testid="seeMoreProfessionalMail">
				<span>
					<MailIcon />
				</span>
				<span>XXXX@XXXX.XXX</span>
			</div>

			<div className={styles.noInfoGroup} data-testid="seeMorePersonalMail">
				<span>
					<ForwardToMailIcon />
				</span>
				<span>XXXX@XXXX.XXX</span>
			</div>
		</>
	);
};

export default EmptyContactDetails;
