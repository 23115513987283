import {type FC, Fragment, useEffect, useMemo, useRef, useState} from "react";
import useDebouncedEffect from "use-debounced-effect";

import {IndustryCard} from "mapx-components";
import {useAppDispatch, useAppSelector} from "hooks";
import {getIndustryReportData} from "store/mapx/project/projectAsyncActions";
import {tooltipCompaniesSelector} from "store/mapx/company/companySelectors";
import {projectSelector} from "store/mapx/project-list/projectListSelectors";
import {updateTooltipCompaniesList} from "store/mapx/company/companyAsyncActions";

import styles from "mapx-pages/Project/Overview/IndustriesReport/industriesReport.module.scss";
import {STIndustryData} from "api/filterOptionsApi/IndustryApi/types";
import {
	industryReportLoadingSelector,
	industryReportSelector,
} from "store/mapx/project/projectSelectors";
import {STProject} from "api/projectApi/types";

const IndustriesReportData: FC = () => {
	const dispatch = useAppDispatch();

	const project: STProject = useAppSelector(projectSelector);
	const industryReportData = useAppSelector(industryReportSelector);
	const industryReportLoading = useAppSelector(industryReportLoadingSelector);

	const [companyId, setCompanyId] = useState<string>("");
	const [removeMouse, setRemoveMouse] = useState<boolean>(false);

	const tooltipCompanies = useAppSelector(tooltipCompaniesSelector);
	const companyToWatch = companyId ? tooltipCompanies[String(companyId)] : null;

	const industryReportLoadingRef = useRef(industryReportLoading);

	useEffect(() => {
		if (project?.id && !industryReportLoadingRef.current) {
			dispatch(getIndustryReportData(project.id));
		}
	}, [dispatch, project?.id]);

	useDebouncedEffect(
		() => {
			if (companyId && removeMouse) {
				dispatch(updateTooltipCompaniesList(companyId));
			}
		},
		1000,
		[companyId, removeMouse],
	);

	const otherData = useMemo(() => {
		const otherData: STIndustryData[] = [];

		if (!industryReportLoading) {
			industryReportData
				?.filter((industry: STIndustryData) => industry.name === null)
				.forEach((item: STIndustryData) => {
					otherData.push(item);
				});
		}

		return otherData;
	}, [industryReportData, industryReportLoading]);

	return (
		<Fragment>
			{!industryReportLoading && (
				<div
					className={styles.industryReportContainer}
					data-testid="industries-report-data"
				>
					{industryReportData?.map((industry: STIndustryData, index: number) => (
						<IndustryCard
							index={index}
							industry={industry}
							name={industry?.name}
							setCompanyId={setCompanyId}
							currentCompanyId={companyId}
							setRemoveMouse={setRemoveMouse}
							key={`${industry?.id}-${index}`}
							companyToWatch={companyToWatch}
						/>
					))}

					{otherData?.map((industry, index) => (
						<IndustryCard
							name="Other"
							index={index}
							industry={industry}
							setCompanyId={setCompanyId}
							currentCompanyId={companyId}
							setRemoveMouse={setRemoveMouse}
							key={`${industry?.id}-${index}`}
							companyToWatch={companyToWatch}
						/>
					))}
				</div>
			)}
		</Fragment>
	);
};
export default IndustriesReportData;
