import React, {useCallback, useEffect, useMemo, useState} from "react";
import {ModalHeader, ModalSeparator} from "components";
import {
	createProjectModal,
	setProjectForm,
	setProjectWorkFlowStep,
} from "store/mapx/project-list/projectListActions";
import {useAppDispatch, useAppSelector, useModalHook} from "hooks";
import {
	createProjectModalSelector,
	getProjectWorkflowStepSelector,
} from "store/mapx/project-list/projectListSelectors";
import ProjectCreationTypes from "mapx-components/Modals/ProjectCreateWithWorkflowsModal/ProjectCreationTypes";
import StartWithCompaniesOptions from "mapx-components/Modals/ProjectCreateWithWorkflowsModal/StartWithCompanies";
import CreateWithAI from "mapx-components/Modals/ProjectCreateWithWorkflowsModal/CreateWithAI";
import ProjectForm from "mapx-components/Modals/ProjectCreateWithWorkflowsModal/ProjectForm";
import {projectInitialState} from "store/mapx/project-list/projectListReducer";
import {TScratchProjectForm} from "api/projectApi/types";
import {STUser} from "api/organisationApi/types";
import {saveProjectFromScratch} from "store/mapx/project-list/projectWorkflow";
import {useHistory} from "react-router-dom";
import {TProjectFormData} from "mapx-components/Modals/ProjectCreateWithWorkflowsModal/ProjectForm/types";
import StartWithJobTitle from "mapx-components/Modals/ProjectCreateWithWorkflowsModal/StartWithJobTitle";
import {hasAccessToExtendedPagesSelector} from "store/mapx/user/userSelectors";

const ProjectCreateWithWorkflowsModal = () => {
	const history = useHistory();

	const dispatch = useAppDispatch();

	const [loading, setLoading] = useState<boolean>(false);

	const currentStep = useAppSelector(getProjectWorkflowStepSelector);

	const modalDisplay = useAppSelector(createProjectModalSelector);

	const hasFullAccess = useAppSelector(hasAccessToExtendedPagesSelector);

	const modalWidth = useMemo(() => {
		if (currentStep) {
			return "588px";
		} else {
			return "996px";
		}
	}, [currentStep]);

	const {modalIsOpen, customStyles, Modal} = useModalHook(
		{
			content: {
				maxWidth: modalWidth,
				borderRadius: "4px",
				width: "99%",
				boxShadow: "0px 25px 40px -10px rgba(79, 75, 74, 0.08)",
			},
		},
		modalDisplay,
	);

	const handleModalHide = useCallback(() => {
		dispatch(createProjectModal(false));
	}, [dispatch]);

	useEffect(() => {
		if (!hasFullAccess) {
			dispatch(setProjectWorkFlowStep("Create With AI"));
		}

		return () => {
			dispatch(setProjectWorkFlowStep(null));
			dispatch(setProjectForm(projectInitialState.projectForm));
		};
	}, [dispatch, hasFullAccess]);

	const handleSubmit = useCallback(
		async (projectFormData: TProjectFormData) => {
			setLoading(true);

			const formData: TScratchProjectForm = {name: projectFormData.name};

			if (projectFormData.users && projectFormData.users?.length > 0) {
				formData["user_ids"] = projectFormData.users.map((item: STUser) =>
					Number(item.user_id),
				);
			}

			if (projectFormData.location) {
				formData["location"] = projectFormData.location.id as string;
			}

			const newProject = await dispatch(saveProjectFromScratch(formData));

			setLoading(false);

			if (newProject) {
				dispatch(createProjectModal(false));

				history.push("/companies");
			}
		},
		[dispatch, history],
	);

	const handleGoButtonClick = useCallback(() => {
		dispatch(setProjectWorkFlowStep(null));
	}, [dispatch]);

	return (
		<Modal
			contentLabel="Modal"
			isOpen={modalIsOpen}
			onRequestClose={handleModalHide}
			style={customStyles}
			title={currentStep ?? "Start a New Project"}
		>
			<ModalHeader title={currentStep ?? "Start a New Project"} onClose={handleModalHide} />

			<ModalSeparator />

			{!currentStep && <ProjectCreationTypes />}

			{currentStep === "Start With Companies" && <StartWithCompaniesOptions />}

			{currentStep === "Start With Job Title" && <StartWithJobTitle />}

			{currentStep === "Create With AI" && <CreateWithAI />}

			{currentStep === "Create From Scratch" && (
				<ProjectForm
					formType={"create-form"}
					handleSubmitButtonClick={handleSubmit}
					loading={loading}
					handleGoBackButtonClick={handleGoButtonClick}
				/>
			)}
		</Modal>
	);
};

export default ProjectCreateWithWorkflowsModal;
