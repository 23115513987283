import React, {type ChangeEvent, useCallback, useMemo, useState} from "react";
import styles from "./styles.module.scss";
import SelectedProjectTypeHeader from "mapx-components/Modals/ProjectCreateWithWorkflowsModal/SelectedProjectTypeHeader";
import CreateWithAIIcon from "mapx-components/Modals/ProjectCreateWithWorkflowsModal/Icons/CreateWithAIIcon";
import {RadioInput, TextInput, ToastContent} from "components";
import FooterButtons from "mapx-components/Modals/ProjectCreateWithWorkflowsModal/FooterButtons";
import {
	createProjectModal,
	setProjectWorkFlowStep,
} from "store/mapx/project-list/projectListActions";
import {useAppDispatch, useAppSelector} from "hooks";
import {TStep} from "mapx-components/Modals/ProjectCreateWithWorkflowsModal/CreateWithAI/types";
import TextSearchRequest from "mapx-components/TextSearchRequest";
import {saveProjectForAI} from "store/mapx/project-list/projectWorkflow";
import {createFreeTextQuickSearch} from "store/mapx/additional-profiles/quickSearchAsyncActions";
import {toast} from "react-toastify";
import {useHistory} from "react-router-dom";
import {SICandidate} from "api/candidateApi/types";
import placeHolderImage from "assets/images/avatar-icon.png";
import type {STProject} from "api/projectApi/types";
import {prepareMoreLikeThisPayloadAndCreateSearchRequest} from "store/mapx/additional-profiles/searchRequestAsyncActions";
import useCandidateSearchService from "services/useCandidateSearchService";
import SelectDropdown from "components/Inputs/SelectDropdown";
import {hasAccessToExtendedPagesSelector} from "store/mapx/user/userSelectors";

const CreateWithAI = () => {
	const dispatch = useAppDispatch();

	const hasFullAccess = useAppSelector(hasAccessToExtendedPagesSelector);

	const {loadingCandidates, fetchCandidates} = useCandidateSearchService();

	const [selectedType, setSelectedType] =
		useState<Nullable<"Text Search" | "Profile Search">>(null);

	const [step, setStep] = useState<TStep>("option select");

	const [name, setName] = useState("");

	const [searchText, setSearchText] = useState<string>("");

	const [loading, setLoading] = useState<boolean>(false);

	const [candidate, setCandidate] = useState<Nullable<SICandidate>>(null);

	const history = useHistory();

	const handleNameChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
		setName(e.target.value);
	}, []);

	const handleGoBack = useCallback(() => {
		if (step === "project name") {
			setStep("option select");
		} else if (step === "text search" || step === "match with profile") {
			setStep("project name");
		} else {
			dispatch(setProjectWorkFlowStep(null));
		}
	}, [dispatch, step]);

	const handleCreateNewProjectButtonClickForTextSearch = useCallback(async () => {
		if (searchText.length < 2) return;

		setLoading(true);

		const newProject = await dispatch(saveProjectForAI({name}));

		if (newProject) {
			const {status, message} = await dispatch(createFreeTextQuickSearch(searchText));

			setLoading(false);

			dispatch(createProjectModal(false));

			history.push("/candidates-text-search");

			if (status !== "success") {
				toast.error(ToastContent, {
					autoClose: 2000,
					closeOnClick: true,
					data: {title: "Error!", description: message},
				});
			}
		}
	}, [dispatch, name, searchText, history]);

	const handleCreateNewProjectButtonClickForMatchProfile = useCallback(async () => {
		if (candidate) {
			setLoading(true);
			const newProject: Nullable<STProject> = await dispatch(saveProjectForAI({name}));

			if (newProject) {
				await dispatch(prepareMoreLikeThisPayloadAndCreateSearchRequest(candidate));

				dispatch(createProjectModal(false));

				history.push(`/candidates-profile-search`);
			}

			setLoading(false);
		}
	}, [candidate, dispatch, history, name]);

	const handleActionButtonClick = useCallback(() => {
		if (step === "option select") {
			setStep("project name");
		} else if (step === "project name") {
			if (selectedType === "Text Search") {
				setStep("text search");
			} else {
				setStep("match with profile");
			}
		} else {
			if (step === "text search") {
				handleCreateNewProjectButtonClickForTextSearch();
			} else {
				handleCreateNewProjectButtonClickForMatchProfile();
			}
		}
	}, [
		step,
		selectedType,
		handleCreateNewProjectButtonClickForTextSearch,
		handleCreateNewProjectButtonClickForMatchProfile,
	]);

	const isActionButtonDisabled = useMemo(() => {
		if (step === "option select") {
			return !selectedType;
		} else if (step === "project name") {
			return !(name !== "" && name?.length > 3);
		} else if (step === "text search") {
			return searchText?.length < 3;
		} else if (step === "match with profile") {
			return candidate === null;
		} else {
			return false;
		}
	}, [name, selectedType, step, searchText, candidate]);

	const headerInfo = useMemo(() => {
		let info: {description: string; title: string};

		if (step === "option select") {
			info = {
				title: "Choose Your AI Search Approach",
				description:
					"Describe the type of person you are looking for or give AI an example profile to find matches to",
			};
		} else if (step === "project name") {
			info = {
				title: "Name Your Project",
				description: "Start by giving your project a name",
			};
		} else {
			if (step === "text search") {
				info = {
					title: "Search With AI",
					description:
						"Include a job title, location and example companies or industries they would work for to get the best matches.",
				};
			} else {
				info = {
					title: "AI Profile Match",
					description: "Enter the name and company of a person to generate matches",
				};
			}
		}

		return info;
	}, [step]);

	const handleEnterPress = (event: React.KeyboardEvent) => {
		if (event.code === "Enter" || event.code === "NumpadEnter") {
			event.preventDefault();

			if (!isActionButtonDisabled) {
				handleActionButtonClick();
			}
		}
	};

	return (
		<div>
			<div className={styles.container} onKeyDown={handleEnterPress}>
				<SelectedProjectTypeHeader
					title={headerInfo.title}
					description={headerInfo.description}
					icon={<CreateWithAIIcon />}
				/>

				{step === "option select" && (
					<div className={styles.content}>
						<RadioInput
							inputName={"AI Text Search"}
							label={"AI Text Search"}
							checked={selectedType === "Text Search"}
							containerClass={styles.inputContainer}
							labelClassName={styles.labelClass}
							description={"Describe the type of person you are looking for"}
							onClick={() => setSelectedType("Text Search")}
						/>

						<RadioInput
							inputName={"AI Profile Search"}
							label={"AI Profile Search"}
							checked={selectedType === "Profile Search"}
							containerClass={styles.inputContainer}
							labelClassName={styles.labelClass}
							description={"Give AI a profile to match"}
							onClick={() => setSelectedType("Profile Search")}
						/>
					</div>
				)}

				{step === "project name" && (
					<div className={styles.content}>
						<TextInput
							label="Project Name*"
							onChange={handleNameChange}
							type="text"
							value={name}
							placeholder={"Enter a project name"}
						/>
					</div>
				)}

				{step === "text search" && (
					<div className={styles.content}>
						<TextSearchRequest
							searchText={searchText}
							setSearchText={setSearchText}
							loading={loading}
							fromProjectModal={true}
						/>
					</div>
				)}

				{step === "match with profile" && (
					<div className={styles.content} data-testid="cprofile">
						<SelectDropdown
							name={"cprofile"}
							inputId={"cprofile"}
							isAsync={true}
							borderColor="#dadada"
							value={candidate}
							onChange={setCandidate}
							loadOptions={fetchCandidates}
							showClearIcon={!loadingCandidates}
							isSearchable={true}
							isClearable={true}
							isLoading={loadingCandidates}
							placeholder={"Give AI a profile to match from"}
							noOptionsMessage={"Search by name and company"}
							noResultsText={
								"No profile found with your query, try to refine your query"
							}
							formatOptionLabel={(candidate: SICandidate) => (
								<div className={styles.dropdownItem}>
									<img
										src={candidate.avatar_url || placeHolderImage}
										alt={candidate.full_name}
									/>
									<span>
										{candidate.full_name}
										{candidate?.positions?.length > 0
											? `, ${candidate?.positions[0].job_title}, ${candidate?.positions[0].company.name}`
											: ""}
									</span>
								</div>
							)}
							menuPortalTarget={document.body}
							menuPosition={"fixed"}
						/>
					</div>
				)}
			</div>

			<FooterButtons
				actionButtonLabel={
					step === "text search" || step === "match with profile"
						? "Create New Project"
						: "Continue"
				}
				handleGoButtonClick={hasFullAccess ? handleGoBack : undefined}
				handleActionButtonClick={handleActionButtonClick}
				isActionButtonDisabled={isActionButtonDisabled}
				isLoading={loading}
			/>
		</div>
	);
};

export default CreateWithAI;
