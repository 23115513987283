import React from "react";

const SpreadSheetIcon = () => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="20"
			height="20"
			viewBox="0 0 20 20"
			fill="none"
			data-testid="spreadsheet-icon"
		>
			<mask
				id="mask0_943_6044"
				style={{maskType: "alpha"}}
				maskUnits="userSpaceOnUse"
				x="0"
				y="0"
				width="20"
				height="20"
			>
				<rect width="20" height="20" fill="#D9D9D9" />
			</mask>
			<g mask="url(#mask0_943_6044)">
				<path
					d="M16 14L13 17L10 14L11.0625 12.9375L12.25 14.125V11H13.75V14.125L14.9375 12.9375L16 14Z"
					fill="#2C3030"
				/>
				<path d="M10 18V19.5H16V18H10Z" fill="#2C3030" />
				<path
					d="M4 16V2H16V10H5.5V11.5H9V13H5.5V14.5H9V16H4ZM5.5 8.5H9.25V6.75H5.5V8.5ZM10.75 8.5H14.5V6.75H10.75V8.5ZM5.5 5.25H9.25V3.5H5.5V5.25ZM10.75 5.25H14.5V3.5H10.75V5.25Z"
					fill="#2C3030"
				/>
			</g>
		</svg>
	);
};

export default SpreadSheetIcon;
