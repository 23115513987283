import React, {useCallback, useMemo, useState} from "react";
import {STUser} from "api/organisationApi/types";
import styles from "./styles.module.scss";
import placeHolderImage from "assets/images/avatar-icon.png";
import SelectDropdown from "components/Inputs/SelectDropdown";
import useUserSearchService from "services/useUserSearchService";
import {useAppDispatch, useAppSelector} from "hooks";
import {projectSelector} from "store/mapx/project-list/projectListSelectors";
import {GeneralButton} from "mapx-components";
import {STProject} from "api/projectApi/types";
import {userSelector} from "store/mapx/user/userSelectors";
import RevokeIcon from "mapx-pages/Project/Share/Icons/revokeIcon";
import {TProjectFormData} from "mapx-components/Modals/ProjectCreateWithWorkflowsModal/ProjectForm/types";
import {updateProject} from "store/mapx/project-list/projectWorkflow";
import {Loader} from "components";
import colors from "styles/themes.module.scss";

const ProjectCollaboration = () => {
	const dispatch = useAppDispatch();

	const [loading, setLoading] = useState(false);

	const [revoking, setRevoking] = useState(false);

	const [users, setUsers] = useState<STUser[]>([]);

	const {loadingUsers, fetchUsers} = useUserSearchService();

	const project: STProject = useAppSelector(projectSelector);

	const user: STUser = useAppSelector(userSelector);

	const handleAddUser = useCallback(async () => {
		if (users?.length > 0) {
			setLoading(true);

			const formData: TProjectFormData = {
				name: project?.name,
			};

			if (project.users && project.users?.length > 0) {
				formData.users = [...users, ...project.users].filter((u) => u.id !== user.user_id);
			}

			await dispatch(updateProject(project.id as number, formData));

			setLoading(false);

			setUsers([]);
		}
	}, [dispatch, project.id, project?.name, project.users, user.user_id, users]);

	const handleRevokeUser = useCallback(
		async (revokedUser: STUser) => {
			if (project.users && project.users?.length > 0) {
				setRevoking(true);

				const formData: TProjectFormData = {
					name: project.name,
					users: [...project.users].filter((u) => u.id !== revokedUser.id),
				};

				await dispatch(updateProject(project.id as number, formData));

				setRevoking(false);
			}
		},
		[dispatch, project?.id, project?.name, project?.users],
	);

	const LoaderComponent = (
		<Loader
			height={24}
			width={24}
			type="ThreeDots"
			customStyle={{display: "flex", alignItems: "center", margin: "0 5px"}}
			color={colors.loaderDotColor}
		/>
	);

	const displayedUsers = useMemo(() => {
		const projectUsers =
			project?.users?.length > 0
				? project?.users.filter((pUser) => pUser.id !== user.user_id)
				: [];

		return [...project.users.filter((pUser) => pUser.id === user.user_id), ...projectUsers];
	}, [project?.users, user]);

	return (
		<div className={styles.container}>
			<div className={styles.inputContainer} data-testid="searchUsers">
				<SelectDropdown
					isAsync={true}
					isMulti={true}
					borderColor="#dadada"
					value={users}
					onChange={setUsers}
					cacheOptions
					loadOptions={fetchUsers}
					showClearIcon={!loadingUsers}
					isSearchable={true}
					isLoading={loadingUsers}
					placeholder={"Invite user by name or email"}
					noOptionsMessage={"Invite user by name or email"}
					noResultsText={"No user found with your query, try to refine your query"}
					formatOptionLabel={(user: STUser) => (
						<div className={styles.dropdownItem}>
							<img src={placeHolderImage} alt={user.first_name} />
							<span>{`${user.first_name} ${user.last_name}`}</span>
						</div>
					)}
					hideIndicator={true}
					isClearable={false}
					hasAddButton={true}
				/>

				<div className={styles.addButtonWrapper}>
					{!loadingUsers && users?.length > 0 && (
						<GeneralButton
							customClass={styles.addButton}
							title={"Add"}
							handleClick={handleAddUser}
							loadingSave={loading}
						/>
					)}
				</div>
			</div>

			<div className={styles.list}>
				{displayedUsers.map((projectUser: STUser) => (
					<div className={styles.userItem} key={projectUser.id}>
						<div className={styles.infoBlock}>
							<div className={styles.img}>
								<img src={placeHolderImage} alt={projectUser.first_name} />
							</div>
							<div className={styles.info}>
								<span
									className={styles.name}
								>{`${projectUser.first_name} ${projectUser.last_name}`}</span>
								<span className={styles.email}>{projectUser.email}</span>
							</div>
						</div>

						<div className={styles.action}>
							{user.user_id === projectUser.id ? (
								"Current User"
							) : revoking ? (
								LoaderComponent
							) : (
								<div
									style={{cursor: "pointer"}}
									onClick={() => handleRevokeUser(projectUser)}
								>
									<RevokeIcon /> Revoke
								</div>
							)}
						</div>
					</div>
				))}
			</div>
		</div>
	);
};

export default ProjectCollaboration;
