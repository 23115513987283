import React, {useCallback, useMemo} from "react";
import {useAppDispatch, useAppSelector} from "hooks";
import {Checkbox, ResetFilters} from "components";
import {
	CandidateCompanyDateRangeOptionsSelector,
	CandidateCompanyEventSizeRangeOptionsSelector,
	CandidateCompanyEventsSelector,
	isDateDropDownActiveOnDEFilterSelector,
	isSizeDropDownActiveOnDEFilterSelector,
	isTypeDropDownActiveOnDEFilterSelector,
} from "store/mapx/filter/DealExperienceFilterSelectors";
import {
	setIsDateDropDownActiveOnDEFilter,
	setIsSizeDropDownActiveOnDEFilter,
	setIsTypeDropDownActiveOnDEFilter,
} from "store/mapx/filter/filterActions";
import useFetchListOptions from "hooks/useFetchListOptions";
import {
	getCandidateCompanyDateRanges,
	getCandidateCompanyEventRanges,
} from "store/mapx/search/searchAsyncActions";
import styles from "./styles.module.scss";
import {
	TCandidateCompanyDateRangeItem,
	TCandidateCompanyEventSizeItem,
	TCandidateCompanyEventTypeItem,
	TDealExperienceSearchFilterProps,
} from "./types";
import useFetchListPaginateOptions from "hooks/useFetchListPaginateOptions";
import {
	getDateRangeLabel,
	getEventSizeLabel,
} from "containers/Filters/PeopleFilters/DealExperienceFilter/utils";
import FilterSection from "./filterSection";

const DealExperienceSearchFilter = ({
	handleResetClick,
	handleChange,
	displayResetFilterOption,
	candidateCompanyTypesSearchQuery,
	CandidateCompanyEventPaginationInfo,
	setCandidateCompanyTypesQueryOnState,
	apiCall,
	CandidateCompanyEventsFilter,
	handleSelectAll,
}: TDealExperienceSearchFilterProps) => {
	const options = useAppSelector(CandidateCompanyEventsSelector);

	const dispatch = useAppDispatch();
	const isSizeDropDownActiveOnDEFilter = useAppSelector(isSizeDropDownActiveOnDEFilterSelector);
	const isDateDropDownActiveOnDEFilter = useAppSelector(isDateDropDownActiveOnDEFilterSelector);
	const isTypeDropDownActiveOnDEFilter = useAppSelector(isTypeDropDownActiveOnDEFilterSelector);

	const CandidateCompanyEventSizeRangeOptions = useAppSelector(
		CandidateCompanyEventSizeRangeOptionsSelector,
	);

	const CandidateCompanyDateRangeOptions = useAppSelector(
		CandidateCompanyDateRangeOptionsSelector,
	);

	const companyDateRange = useFetchListOptions({
		options: CandidateCompanyDateRangeOptions,
		callbackMethod: getCandidateCompanyDateRanges,
	});

	const companyEventSizeRange = useFetchListOptions({
		options: CandidateCompanyEventSizeRangeOptions,
		callbackMethod: getCandidateCompanyEventRanges,
	});

	const {loading, listInnerRef, page, setPage, data} = useFetchListPaginateOptions({
		options: options,
		apiCall,
		initialSearchQuery: candidateCompanyTypesSearchQuery,
		// eslint-disable-next-line
		// @ts-ignore
		setQueryCallbackOnState: setCandidateCompanyTypesQueryOnState,
	});

	const onScroll = useCallback(() => {
		try {
			if (!!setPage && !loading && CandidateCompanyEventPaginationInfo?.pages > page) {
				if (listInnerRef.current) {
					const {scrollTop, scrollHeight, clientHeight} = listInnerRef.current;
					if (Math.ceil(scrollTop) + clientHeight >= scrollHeight - 1) {
						setPage(CandidateCompanyEventPaginationInfo.page + 1);
					}
				}
			}
		} catch (e) {
			console.log(e);
		}
	}, [setPage, loading, CandidateCompanyEventPaginationInfo, page, listInnerRef]);

	const selectedDateRange = useMemo(() => {
		return getDateRangeLabel(CandidateCompanyEventsFilter.dates);
	}, [CandidateCompanyEventsFilter]);

	const selectedEventSizes = useMemo(() => {
		return getEventSizeLabel(CandidateCompanyEventsFilter.size);
	}, [CandidateCompanyEventsFilter]);

	return (
		<div>
			<div style={{margin: "10px 0"}}>
				{displayResetFilterOption && (
					<ResetFilters
						parentStyle={{color: "#5A5A5A", marginRight: 19}}
						onClick={() => handleResetClick("all")}
						displayIcon={true}
					>
						Clear All
					</ResetFilters>
				)}
			</div>

			<FilterSection<TCandidateCompanyEventTypeItem>
				label="Experience of event type"
				isActive={isTypeDropDownActiveOnDEFilter}
				selectedValues={CandidateCompanyEventsFilter.types}
				count={CandidateCompanyEventsFilter.types.length}
				toggleActive={() =>
					dispatch(setIsTypeDropDownActiveOnDEFilter(!isTypeDropDownActiveOnDEFilter))
				}
				data={data}
				onScroll={onScroll}
				listInnerRef={listInnerRef}
				handleSelectAll={handleSelectAll.selectTypes}
				showSelectAll={data?.length !== CandidateCompanyEventsFilter?.types?.length}
				handleResetClick={handleResetClick}
				resetFilterOption="types"
				renderItem={(s) => (
					<Checkbox
						borderColor="#0C5850"
						containerClass={styles.checkboxContainer}
						isChecked={CandidateCompanyEventsFilter.types.includes(s.name)}
						key={s.id}
						label={`${s.name}`}
						onChange={() => handleChange.typeChange(s)}
						value={s.name}
					/>
				)}
				loading={loading}
			/>

			<FilterSection<TCandidateCompanyEventSizeItem>
				label="Event size"
				isActive={isSizeDropDownActiveOnDEFilter}
				selectedValues={[selectedEventSizes]}
				count={CandidateCompanyEventsFilter.size.length}
				toggleActive={() =>
					dispatch(setIsSizeDropDownActiveOnDEFilter(!isSizeDropDownActiveOnDEFilter))
				}
				showSelectAll={false}
				data={companyEventSizeRange.filteredData}
				onScroll={onScroll}
				listInnerRef={listInnerRef}
				handleResetClick={handleResetClick}
				resetFilterOption="size"
				renderItem={(s) => (
					<Checkbox
						borderColor="#0C5850"
						containerClass={styles.checkboxContainer}
						isChecked={CandidateCompanyEventsFilter.size.includes(s.id)}
						key={s.id}
						label={`${s.name}`}
						onChange={() => handleChange.sizeChange(s)}
						value={s.name}
					/>
				)}
				loading={false}
			/>

			<FilterSection<TCandidateCompanyDateRangeItem>
				label="Date range"
				isActive={isDateDropDownActiveOnDEFilter}
				selectedValues={[selectedDateRange]}
				count={selectedDateRange ? selectedDateRange.split(",").length : 0}
				toggleActive={() =>
					dispatch(setIsDateDropDownActiveOnDEFilter(!isDateDropDownActiveOnDEFilter))
				}
				showSelectAll={
					companyDateRange.filteredData?.length !== selectedDateRange?.split(",")?.length
				}
				data={companyDateRange.filteredData}
				onScroll={onScroll}
				listInnerRef={listInnerRef}
				handleResetClick={handleResetClick}
				handleSelectAll={handleSelectAll.selectDates}
				resetFilterOption="dates"
				renderItem={(s) => (
					<Checkbox
						borderColor="#0C5850"
						containerClass={styles.checkboxContainer}
						isChecked={selectedDateRange.includes(s.name)}
						key={s.id}
						label={`${s.name}`}
						onChange={() => handleChange.dateChange(s)}
						value={s.name}
					/>
				)}
				loading={false}
			/>
		</div>
	);
};

export default DealExperienceSearchFilter;
